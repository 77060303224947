export const HollowedCheckIcon = (): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='9'
      fill='none'
      viewBox='0 0 11 9'
    >
      <path
        fill='#fff'
        d='M4.512 8.5a.449.449 0 01-.32-.134L.13 4.244a.451.451 0 01.006-.636L1.74 2.025a.45.45 0 01.635.004l1.978 2.007a.225.225 0 00.32 0L8.034.634A.45.45 0 018.67.63l1.605 1.585a.449.449 0 01-.006.645.462.462 0 01-.64-.019L8.572 1.795a.3.3 0 00-.424.003L4.833 5.154a.45.45 0 01-.64 0l-1.931-1.96a.3.3 0 00-.424-.003l-.54.532a.3.3 0 00-.003.424l3.057 3.102a.225.225 0 00.32 0l3.75-3.797a.45.45 0 01.644.006.462.462 0 01-.019.64L4.832 8.365a.45.45 0 01-.32.134z'
      />
    </svg>
  );
};

export function FilledCheckIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={props.className ?? 'w-5 h-5 fill-current'}
      viewBox='0 0 20 20'
    >
      <path
        fillRule='evenodd'
        d='M16.713 4.598c.524.458.577 1.254.118 1.777l-8.178 9.34-5.36-4.637a1.259 1.259 0 111.65-1.905l3.464 2.997 6.527-7.454a1.261 1.261 0 011.779-.118z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
}
